export default [
  {
    path: "/citizens/hospital-services/:id",
    name: "hospital-services",
    component: () =>
      import(
        /* webpackChunkName: "hospital-service" */ "@/views/hospital-services/HospitalServicesView.vue"
      ),
    props: (route) => ({
      id: String(route.params.id),
    }),
    meta: {
      activeIcon: "Citizens",
      routeName: "Hospital Services",
    },
  },
  {
    path: "/citizens/hospital-services/:id/view/:hospital_service_id",
    name: "hospital-services-information",
    component: () =>
      import(
        /* webpackChunkName: "hospital-service" */ "@/views/hospital-services/HospitalServiceInformationView.vue"
      ),
    props: (route) => ({
      id: String(route.params.id),
      hospital_service_id: String(route.params.hospital_service_id),
    }),
    meta: {
      activeIcon: "Citizens",
      routeName: "Hospital Service Details",
    },
  },
  {
    path: "/citizens/hospital-services/:id/add-service",
    name: "add-hospital-service",
    component: () =>
      import(
        /* webpackChunkName: "hospital-service" */ "@/views/hospital-services/HospitalServiceControlView.vue"
      ),
    props: (route) => ({
      action: "add",
      id: String(route.params.id),
    }),
    meta: {
      activeIcon: "Citizens",
      routeName: "Add Hospital Service",
    },
  },
  {
    path: "/citizens/hospital-services/:id/edit-service/:hospital_service_id",
    name: "edit-hospital-service",
    component: () =>
      import(
        /* webpackChunkName: "hospital-service" */ "@/views/hospital-services/HospitalServiceControlView.vue"
      ),
    props: (route) => ({
      id: String(route.params.id),
      hospital_service_id: String(route.params.hospital_service_id),
      action: "edit",
    }),
    meta: {
      activeIcon: "Citizens",
      routeName: "Edit Hospital Service",
    },
  },
];
