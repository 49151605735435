import Vuex from "vuex";
import Vue from "vue";

Vue.use(Vuex);

export const diagnostics = {
  namespaced: true,
  state: () => ({
    diagnostics: null,
    diagnostic: null,
  }),
  getters: {},
  mutations: {
    SET_DIAGNOSTICS: (state, data) => (state.diagnostics = data),
    SET_DIAGNOSTIC: (state, data) => (state.diagnostic = data),
  },
  actions: {
    fetchDiagnostics: function ({ commit }) {
      this.$store.dispatch("loading/triggerLoader", "table");
      const url = `enums/hospital-services/diagnostic-types`;
      return this.$axios
        .get(url)
        .then((response) => {
          commit("SET_DIAGNOSTICS", response.data.diagnosticType);
          this.$store.dispatch("loading/detriggerLoader", "table");
        })
        .catch((error) => {
          console.error("Error fetching diagnostics: ", error);
          this.$store.dispatch("loading/detriggerLoader", "table");
        });
    },
    showDiagnostic: function ({ commit }, diagnosticId) {
      const url = `enums/hospital-services/diagnostic-types/${diagnosticId}`;
      return this.$axios
        .get(url)
        .then((response) => {
          commit("SET_DIAGNOSTIC", response.data.diagnosticType);
        })
        .catch((error) => {
          console.error("Error fetching diagnostic: ", error);
        });
    },
    createDiagnostic: function ({ dispatch }, payload) {
      this.$store.dispatch("loading/triggerLoader", "function");
      const url = `enums/hospital-services/diagnostic-types`;
      return this.$axios
        .post(url, payload)
        .then((response) => {
          dispatch("fetchDiagnostics");
          this.$store.commit("alerts/SET_SHOW_ALERT", response.data.message);
          this.$store.dispatch("loading/detriggerLoader", "function");
        })
        .catch((error) => {
          console.error("Error creating diagnostic: ", error);
          this.$store.commit("alerts/SET_SHOW_ERROR", error.response.data.message);
          this.$store.dispatch("loading/detriggerLoader", "function");
        });
    },
    updateDiagnostic: function ({ dispatch }, { diagnosticId, payload }) {
      this.$store.dispatch("loading/triggerLoader", "function");
      const url = `enums/hospital-services/diagnostic-types/${diagnosticId}`;
      return this.$axios
        .put(url, payload)
        .then((response) => {
          dispatch("fetchDiagnostics");
          this.$store.commit("alerts/SET_SHOW_ALERT", response.data.message);
          this.$store.dispatch("loading/detriggerLoader", "function");
        })
        .catch((error) => {
          console.error("Error updating diagnostic: ", error);
          this.$store.commit("alerts/SET_SHOW_ERROR", error.response.data.message);
          this.$store.dispatch("loading/detriggerLoader", "function");
        });
    },
    deleteDiagnostic: function ({ dispatch }, diagnosticId) {
      this.$store.dispatch("loading/triggerLoader", "function");
      const url = `enums/hospital-services/diagnostic-types/${diagnosticId}`;
      return this.$axios
        .delete(url)
        .then((response) => {
          dispatch("fetchDiagnostics");
          this.$store.commit("alerts/SET_SHOW_ALERT", response.data.message);
          this.$store.dispatch("loading/detriggerLoader", "function");
        })
        .catch((error) => {
          console.error("Error updating diagnostic: ", error);
          this.$store.commit("alerts/SET_SHOW_ERROR", error.response.data.message);
          this.$store.dispatch("loading/detriggerLoader", "function");
        });
    },
  },
};
