import Vuex from "vuex";
import Vue from "vue";

Vue.use(Vuex);

export const specialties = {
  namespaced: true,
  state: () => ({
    specialties: null,
    specialty: null,
  }),
  getters: {},
  mutations: {
    SET_SPECIALTIES: (state, data) => (state.specialties = data),
    SET_SPECIALTY: (state, data) => (state.specialty = data),
  },
  actions: {
    fetchSpecialties: function ({ commit }) {
      this.$store.dispatch("loading/triggerLoader", "table");
      const url = `enums/hospital-services/specialties`;
      return this.$axios
        .get(url)
        .then((response) => {
          commit("SET_SPECIALTIES", response.data.specialty);
          this.$store.dispatch("loading/detriggerLoader", "table");
        })
        .catch((error) => {
          console.error("Error fetching specialties: ", error);
          this.$store.dispatch("loading/detriggerLoader", "table");
        });
    },
    showSpecialty: function ({ commit }, specialtyId) {
      const url = `enums/hospital-services/specialties/${specialtyId}`;
      return this.$axios
        .get(url)
        .then((response) => {
          commit("SET_SPECIALTY", response.data.specialty);
        })
        .catch((error) => {
          console.error("Error fetching specialty: ", error);
        });
    },
    createSpecialty: function ({ dispatch }, payload) {
      this.$store.dispatch("loading/triggerLoader", "function");
      const url = `enums/hospital-services/specialties`;
      return this.$axios
        .post(url, payload)
        .then((response) => {
          dispatch("fetchSpecialties");
          this.$store.commit("alerts/SET_SHOW_ALERT", response.data.message);
          this.$store.dispatch("loading/detriggerLoader", "function");
        })
        .catch((error) => {
          console.error("Error creating specialty: ", error);
          this.$store.commit("alerts/SET_SHOW_ERROR", error.response.data.message);
          this.$store.dispatch("loading/detriggerLoader", "function");
        });
    },
    updateSpecialty: function ({ dispatch }, { specialtyId, payload }) {
      this.$store.dispatch("loading/triggerLoader", "function");
      const url = `enums/hospital-services/specialties/${specialtyId}`;
      return this.$axios
        .put(url, payload)
        .then((response) => {
          dispatch("fetchSpecialties");
          this.$store.commit("alerts/SET_SHOW_ALERT", response.data.message);
          this.$store.dispatch("loading/detriggerLoader", "function");
        })
        .catch((error) => {
          console.error("Error updating specialty: ", error);
          this.$store.commit("alerts/SET_SHOW_ERROR", error.response.data.message);
          this.$store.dispatch("loading/detriggerLoader", "function");
        });
    },
    deleteSpecialty: function ({ dispatch }, specialtyId) {
      this.$store.dispatch("loading/triggerLoader", "function");
      const url = `enums/hospital-services/specialties/${specialtyId}`;
      return this.$axios
        .delete(url)
        .then((response) => {
          dispatch("fetchSpecialties");
          this.$store.commit("alerts/SET_SHOW_ALERT", response.data.message);
          this.$store.dispatch("loading/detriggerLoader", "function");
        })
        .catch((error) => {
          console.error("Error updating specialty: ", error);
          this.$store.commit("alerts/SET_SHOW_ERROR", error.response.data.message);
          this.$store.dispatch("loading/detriggerLoader", "function");
        });
    },
  },
};
