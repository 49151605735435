import Vuex from "vuex";
import Vue from "vue";

Vue.use(Vuex);

export const loading = {
  namespaced: true,
  state: () => ({
    loader: {
      table: false,
      function: false,
    },
  }),
  getters: {},
  mutations: {
    SET_LOADER: (state, data) => (state.loader = { ...data }),
  },
  actions: {
    triggerLoader: function ({ commit }, type) {
      commit("SET_LOADER", { [type]: true });
    },
    detriggerLoader: function ({ commit }, type) {
      commit("SET_LOADER", { [type]: false });
    },
  },
};
