import Vuex from "vuex";
import Vue from "vue";
import store from "../..";
import { functions } from "@/utils/functions";

Vue.use(Vuex);

export const accounts = {
  namespaced: true,
  state: () => ({
    users_index: null,
    loading: false,
  }),
  getters: {},
  mutations: {
    SET_USERS_INDEX: (state, users) => (state.users_index = users),
    SET_LOADING: (state, value) => (state.loading = value),
  },
  actions: {
    fetchUsersIndex({ commit }, queryParams = {}) {
      this.$store.dispatch("loading/triggerLoader", "table");
      const url = `account/users`;
      const query = functions.query(url, queryParams);

      return this.$axios
        .get(query)
        .then((response) => {
          commit("SET_USERS_INDEX", response.data.data);
          this.$store.dispatch("loading/detriggerLoader", "table");
        })
        .catch((error) => {
          console.error("Error Fetching Users Index: ", error);
          this.$store.dispatch("loading/detriggerLoader", "table");
        });
    },
    changeUserPassword({ dispatch }, { user_id, data }) {
      this.$store.dispatch("loading/triggerLoader", "function");
      const url = `account/users/${user_id}/change-others-password`;
      return this.$axios
        .put(url, data)
        .then((response) => {
          dispatch("fetchUsersIndex");
          this.$store.commit("alerts/SET_SHOW_ALERT", response.data.message);
          this.$store.dispatch("loading/detriggerLoader", "function");
        })
        .catch((error) => {
          this.$store.commit("alerts/SET_SHOW_ERROR", error.response.data.message);
          this.$store.dispatch("loading/detriggerLoader", "function");
          console.error("Error changing password: ", error);
        });
    },
    createNewUser({ commit, dispatch }, data) {
      this.$store.dispatch("loading/triggerLoader", "function");
      commit("SET_LOADING", true);
      const url = `account/register`;
      return this.$axios
        .post(url, data)
        .then((response) => {
          dispatch("fetchUsersIndex");
          this.$store.commit("alerts/SET_SHOW_ALERT", response.data.message);
          this.$store.dispatch("loading/detriggerLoader", "function");
        })
        .catch((error) => {
          this.$store.commit("alerts/SET_SHOW_ERROR", error.response.data.message);
          this.$store.dispatch("loading/detriggerLoader", "function");
          console.error("Failed to Register User: ", error);
        });
    },
    deleteUser({ commit, dispatch }, user_id) {
      this.$store.dispatch("loading/triggerLoader", "function");
      const url = `account/users/${user_id}`;
      return this.$axios
        .delete(url)
        .then((response) => {
          commit("SET_LOADING", false);
          dispatch("fetchUsersIndex");
          this.$store.commit("alerts/SET_SHOW_ALERT", response.data.message);
          this.$store.dispatch("loading/detriggerLoader", "function");
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          console.error("Failed to Delete User: ", error);
          this.$store.commit("alerts/SET_SHOW_ERROR", error.response.data.message);
          this.$store.dispatch("loading/detriggerLoader", "function");
        });
    },
    toggleAccountStatus: function ({ commit, dispatch }, { user_id, payload }) {
      commit("SET_LOADING", true);
      const url = `account/root/${user_id}/toggle-status`;
      return this.$axios
        .patch(url, payload)
        .then((response) => {
          commit("SET_LOADING", false);
          dispatch("fetchUsersIndex");
          store.commit("alerts/SET_SHOW_ALERT", response.data.message);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          console.error("Failed to toggle account status", error);
          store.commit("alerts/SET_SHOW_ERROR", error.response.data.message);
        });
    },
  },
};
