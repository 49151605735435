// Authentication Object
import { auth } from "@/utils/auth";

// Route Modules
import doctor from "./doctor";
import citizens from "./citizens";
import hospitalServices from "./hospital-services";
import enums from "./enums";
import dialysis from "./dialysis";

const publicRoutes = [
  "reroute",
  "public-file-view",
  "Minalin Dialysis Center",
  "login",
];

export function checkLoggedIn(to, from, next) {
  const isLoggedIn = auth.user();
  const userRole = auth.role();

  if (!isLoggedIn && !publicRoutes.includes(to.name)) {
    next({ name: "login" });
  } else if (isLoggedIn && to.name === "login") {
    next({ name: "dashboard" });
  } else if (userRole === "ADMIN" && !isAllowedRoutes(to.name, "ADMIN")) {
    next({ name: "dashboard" });
  } else if (userRole === "ROOT" && !isAllowedRoutes(to.name, "ROOT")) {
    next({ name: "dashboard" });
  } else if (userRole === "DOCTOR" && !isAllowedRoutes(to.name, "DOCTOR")) {
    next({ name: "dashboard" });
  } else if (userRole === "ENCODER" && !isAllowedRoutes(to.name, "ENCODER")) {
    next({ name: "dashboard" });
  } else if (
    userRole === "VIP_ENCODER" &&
    !isAllowedRoutes(to.name, "VIP_ENCODER")
  ) {
    next({ name: "dashboard" });
  } else if (
    userRole === "DIALYSIS_ENCODER" &&
    !isAllowedRoutes(to.name, "DIALYSIS_ENCODER")
  ) {
    next({ name: "dashboard" });
  } else {
    next();
  }
}

function isAllowedRoutes(routeName, userRole) {
  let allowedRoutes = [];

  if (userRole === "DOCTOR") {
    allowedRoutes = [...doctor.map(({ name }) => name)];
  } else if (userRole === "ADMIN" || userRole === "ROOT") {
    allowedRoutes = [
      ...citizens.map(({ name }) => name),
      ...hospitalServices.map(({ name }) => name),
      ...enums.map(({ name }) => name),
    ];
    if (userRole === "ROOT") {
      allowedRoutes.push("dialysis-queuing");
    }
  } else if (userRole === "ENCODER" || userRole === "VIP_ENCODER") {
    allowedRoutes = [
      "citizens",
      "register",
      "edit",
      "details",
      ...hospitalServices.map(({ name }) => name),
    ];
  } else if (userRole === "DIALYSIS_ENCODER") {
    allowedRoutes = [...dialysis.map(({ name }) => name)];
  }

  // Allow certain roles to access these routes
  if (userRole) allowedRoutes.push("management", "dashboard");

  //Allow all roles to use these routes:
  allowedRoutes.push("reroute", "public-file-view", "Minalin Dialysis Center");

  // Check if the provided routeName is in the restrictedRoutes array
  return allowedRoutes.includes(routeName);
}
